/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useMutation } from 'react-query';
import queryClient from '../../queryClient';

export function useSignup() {
  interface RequestData {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
  }

  const mutationFn = async ({
    email,
    firstName,
    lastName,
    password
  }: RequestData): Promise<null> => {
    return axios.post('/api/user-signup', {
      email,
      firstName,
      lastName,
      password,
      currentUrl: window.location.href
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('user-data');
    },
    onError: () => {
      console.error('error signing up');
    }
  });
}
