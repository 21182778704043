import {
  isWithinInterval,
  subHours,
  differenceInDays,
  startOfDay,
  endOfDay,
  parseISO,
  isBefore,
  isAfter,
  differenceInCalendarDays,
  isToday
} from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import config from '@configFile';

export function daysUntil(date: string | Date): number {
  const today = new Date();
  return differenceInDays(new Date(date), today);
}

export const daysRemaining = (expires: string | Date) => {
  const days = daysUntil(expires);
  if (days === 1) {
    return '1 day remaining';
  }
  return days > 0 ? `${days} days remaining` : 'ends tonight';
};

export const formatDateAdmin = (date: string | Date): string => {
  return format(new Date(date), 'EEEE LLL do, yyyy hh:mm a');
};

export const formatDateTime = (date: Date): string => {
  return format(date, 'MMM d, yyyy @ h:mm a zzz');
};

export const isDateWithinLast24Hours = (dateToCheck: Date) => {
  const now = new Date();
  const twentyFourHoursAgo = subHours(now, 24);

  const interval = {
    start: twentyFourHoursAgo,
    end: now
  };

  return isWithinInterval(dateToCheck, interval);
};

export const formattedDate = format(new Date(), 'MMM. do, yyyy');

export const formatDateUpdatedToday = () => {
  return `Updated ${formattedDate}`;
};

const getCurrentTimeInPacific = (): Date => {
  const timeZone = 'America/Los_Angeles'; // Pacific Time Zone
  const now = new Date();
  const zonedDate = utcToZonedTime(now, timeZone);
  return zonedDate;
};

const getStartOfDateInPacific = (startDate: string): string => {
  const timeZone = 'America/Los_Angeles';
  const date = parseISO(startDate); // Parse the input date string
  const zonedDate = utcToZonedTime(date, timeZone); // Convert the date to the Pacific Time Zone
  const startOfDate = startOfDay(zonedDate); // Get the start of the day in the Pacific Time Zone
  return format(startOfDate, 'yyyy-MM-dd HH:mm:ss');
};

const getEndOfDateInPacific = (endDate: string): string => {
  const timeZone = 'America/Los_Angeles';
  const date = parseISO(endDate); // Parse the input date string
  const zonedDate = utcToZonedTime(date, timeZone); // Convert the date to the Pacific Time Zone
  const endOfDate = endOfDay(zonedDate); // Get the start of the day in the Pacific Time Zone
  return format(endOfDate, 'yyyy-MM-dd HH:mm:ss');
};

const isCurrentDateInRange = (startDate: string, endDate: string): boolean => {
  const currentPacificTime = getCurrentTimeInPacific();
  const start = new Date(getStartOfDateInPacific(startDate));
  const end = new Date(getEndOfDateInPacific(endDate));

  if (isBefore(currentPacificTime, end) && isAfter(currentPacificTime, start)) {
    return true;
  }

  return false;
};

const isTodayBefore = (startDate: string): boolean => {
  const currentPacificTime = getCurrentTimeInPacific();
  const start = new Date(getStartOfDateInPacific(startDate));

  if (isBefore(currentPacificTime, start)) {
    return true;
  }

  return false;
};

export const isBeforePrimeDay = (): boolean => {
  return isTodayBefore(config.DATES.PRIME_DAY_START);
};

export const isBeforeLaborDay = (): boolean => {
  return isTodayBefore(config.DATES.LABOR_DAY_START);
};

export const isLaborDay = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.LABOR_DAY_START,
    config.DATES.LABOR_DAY_END
  );
};

export const isPrimeDay = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.PRIME_DAY_START,
    config.DATES.PRIME_DAY_END
  );
};

export const isBlackFridayDealsWeek = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.BLACK_FRIDAY_WEEK_START,
    config.DATES.BLACK_FRIDAY_WEEK_END
  );
};

export const isBlackFriday = (): boolean => {
  // is today black friday
  return isToday(parseISO(config.DATES.BLACK_FRIDAY));
  // return isCurrentDateInRange(config.DATES.BLACK_FRIDAY, config.DATES.BLACK_FRIDAY);
};

export const isBeforeBlackFriday = (): boolean => {
  return isTodayBefore(config.DATES.BLACK_FRIDAY_WEEK_START);
};

export const isCyberMonday = (): boolean => {
  // console.log('isCyberMonday', config.DATES.CYBER_MONDAY_START, config.DATES.CYBER_MONDAY_END);
  return isCurrentDateInRange(
    config.DATES.CYBER_MONDAY_START,
    config.DATES.CYBER_MONDAY_END
  );
};

export const displayShippingCutoffMessage = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.SHIPPING_CUTOFF_MESSAGE_START,
    config.DATES.SHIPPING_CUTOFF_MESSAGE_END
  );
};

export const displayTwelveDaysOfDeals = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.TWELVE_DAYS_OF_DEALS_START,
    config.DATES.TWELVE_DAYS_OF_DEALS_END
  );
};

export const isBeforeCyberMonday = (): boolean => {
  return isTodayBefore(config.DATES.CYBER_MONDAY_START);
};

export const getDaysBetweenNowAndDate = (date: string): number => {
  const timeZone = 'America/Los_Angeles'; // PST timezone

  // Step 1: Get the current date in PST timezone and normalize to midnight PST
  const now = utcToZonedTime(new Date(), timeZone);
  const todayMidnightPST = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );
  // console.log('Current Date (PST):', now.toISOString());
  // console.log('Today Midnight PST:', todayMidnightPST.toISOString());

  // Step 2: Set the target date explicitly to midnight PST
  const [year, month, day] = date.split('-').map(Number);
  const targetDateMidnightPST = new Date(year, month - 1, day); // Explicit midnight PST
  // console.log('Target Date Midnight PST:', targetDateMidnightPST.toISOString());

  // Step 3: Calculate the difference in calendar days
  const difference = differenceInCalendarDays(
    todayMidnightPST,
    targetDateMidnightPST
  );
  // console.log('Difference in Calendar Days:', difference);

  // Step 4: Include today in the count
  const daysBetween = difference + 1;
  // console.log('Final Days Between (Including Today):', daysBetween);

  return daysBetween;
};
