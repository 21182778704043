export default {
  // 1 min, former value was 3 mins
  EMAIL_MODAL_DELAY: 60000,
  SHOW_EARLY_DEALS: true,
  SHOW_SS_SEARCH: true,
  shouldLogTrackUse: true,
  // Over write default settings here...
  TEST_API: 'https://jungle.deals/test',
  domain: process.env.TEST_DOMAIN || 'jungle.deals',
  API_PREFIX: 'https://www.jungle.deals',
  // Controls whether to use cache - useful for list testing
  USE_LIST_CACHE: process.env.USE_LIST_CACHE || false,
  DAYS_BEFORE_EVENT_COUNTER: 22,
  showErrorOnPage: false,
  DATES: {
    PRIME_DAY_START: '2024-10-08',
    PRIME_DAY_END: '2024-10-09',

    LABOR_DAY_START: '2024-08-24',
    LABOR_DAY_END: '2024-09-04',

    BLACK_FRIDAY_WEEK_START: '2024-11-20',
    BLACK_FRIDAY_WEEK_END: '2024-11-29',
    BLACK_FRIDAY: '2024-11-29',

    CYBER_MONDAY_START: '2024-11-30',
    CYBER_MONDAY_END: '2024-12-02',

    TWELVE_DAYS_OF_DEALS_START: '2024-12-03',
    TWELVE_DAYS_OF_DEALS_END: '2024-12-14',

    SHIPPING_CUTOFF_MESSAGE_START: '2024-12-15',
    SHIPPING_CUTOFF_MESSAGE_END: '2024-12-23'
  },
  USE_SERVICE_WORKER: true,
  USE_REACT_QUERY_CACHE: true,
  CURRENT_URL: 'https://www.jungle.deals',
  hideFavoritesPage: false
};
