/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useMutation } from 'react-query';
import queryClient from '../queryClient';
import { useSnackbar } from './useSnackbar';

export function useSignin() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    email?: string;
    password?: string;
    credential?: string;
    isSignUp?: boolean;
  }

  const mutationFn = async ({
    email,
    password,
    credential
  }: RequestData): Promise<{
    hasAccount: boolean;
    isSuspended: boolean;
  }> => {
    const { data } = await axios.post(`/api/user-signin`, {
      email,
      password,
      credential,
      currentUrl: window.location.href
    });

    queryClient.refetchQueries('user-data');

    return data;
  };

  return useMutation(mutationFn, {
    onError: (_data, req) => {
      if (req?.isSignUp) {
        showMessage(`Error signing up!`);
      } else {
        showMessage(`Error logging in!`);
      }
    },
    onSuccess: (data) => {
      if (!data.isSuspended) {
        showMessage(`Successfully logged in!`);
      }
    }
  });
}
