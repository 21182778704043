import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useEffect
} from 'react';
import { format } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import config from '@configFile';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';
import Signin from '../components/Signin';
import Email from '../pages/Email/Email';
import useVisibilityChange from './useVisibilityChange';
import { useGetUserData } from './useGetUserData';
import useLocalStorage from './useLocalStorage';

interface LoginModalContextType {
  showNonLoggedInModal: ({
    onLoginCBFn,
    modalMessage,
    featureName
  }: {
    onLoginCBFn?: () => void;
    modalMessage?: string;
    featureName: string;
  }) => void;
  handleClose: (didLogIn?: boolean) => void;
}

const LoginModalContext = createContext<LoginModalContextType | null>(null);

export const useSignUpModal = () => {
  const context = useContext(LoginModalContext);
  if (!context) {
    throw new Error('useSignUpModal must be used within a LoginModalProvider');
  }
  return context;
};

export const LoginModalProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const nextMailingDate = format(new Date(), 'qqq—yyyy');
  const isActive = useVisibilityChange();

  const [hasSeenSignUpMessageRecently, setHasSeenSignUpMessageRecently] =
    useLocalStorage(`email-sign-up-modal-shown—${nextMailingDate}`, false);
  const [hasVisitedWebsiteBefore, setHasVisitedWebsiteBefore] = useLocalStorage(
    'hasVisitedWebsiteBefore',
    false
  );
  const [loginModalMessage, setLoginModalMessage] = useState(
    'Login to use this feature'
  );
  const [isSecondVisit, setIsSecondVisit] = useState(
    hasVisitedWebsiteBefore && !hasSeenSignUpMessageRecently
  );
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null); // eslint-disable-line
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [currentFeatureName, setCurrentFeatureName] = useState('noFeature' as string); // eslint-disable-line
  const [callbackFn, setCallbackFn] = useState<() => void>(() => () => {});
  const { data: user, isLoading: isLoadingUser } = useGetUserData();

  useEffect(() => {
    if (!hasVisitedWebsiteBefore) {
      setTimeout(() => {
        setHasVisitedWebsiteBefore(true);
      }, 1000);
    }
  }, [hasVisitedWebsiteBefore]);

  useEffect(() => {
    // Do not show to logged in users
    // Of if the user is here first time
    // or if the sign in modal is open
    // or if the mailing list message is closed
    if (
      isSecondVisit &&
      !isLoadingUser &&
      !user &&
      !user?.firstName &&
      !modalOpen &&
      !hasSeenSignUpMessageRecently &&
      !window?.location?.href?.includes('email') &&
      isActive &&
      !timeoutId
    ) {
      const id = setTimeout(() => {
        if (!user && !user?.firstName) {
          trackUse({
            item: 'email-sign-up-modal-shown',
            value: 'shown',
            type: 'ACTION'
          });

          logPostHogEvent('email-sign-up-modal-shown', {
            type: 'ACTION'
          });

          setEmailModalOpen(true);
          setHasSeenSignUpMessageRecently(true);
          setIsSecondVisit(false);
        }
      }, config.EMAIL_MODAL_DELAY);

      if (id) {
        setTimeoutId(id);
      }
    }
  }, [
    isSecondVisit,
    user,
    isLoadingUser,
    isActive,
    hasSeenSignUpMessageRecently
  ]);

  useEffect(() => {
    if (user?.hasVerifiedEmail) {
      // wait 1 second
      setTimeout(() => {
        callbackFn();
        handleClose(true);
      }, 1000);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, [user]);

  const showNonLoggedInModal = ({
    onLoginCBFn = () => {},
    modalMessage = 'Login to use this feature',
    featureName
  }: {
    onLoginCBFn?: () => void;
    modalMessage?: string;
    featureName: string;
  }) => {
    // Now, onLoginCBFn and modalMessage have default values if not provided
    setCurrentFeatureName(featureName);
    setCallbackFn(() => onLoginCBFn);
    setModalOpen(true);
    setLoginModalMessage(modalMessage);
    trackUse({
      item: `a-user-feature-only`,
      value: `${featureName}-shown`,
      type: 'ACTION'
    });
  };

  const handleClose = (didLogIn: boolean = false) => {
    if (modalOpen) {
      if (didLogIn) {
        trackUse({
          item: 'a-user-feature-only',
          value: `logged-in-yay-${currentFeatureName}`,
          type: 'ACTION'
        });
      } else {
        trackUse({
          item: 'a-user-feature-only',
          value: `logged-in-nay-${currentFeatureName}`,
          type: 'ACTION'
        });
      }

      setTimeout(() => {
        setCallbackFn(() => () => {});
      }, 50);
      setModalOpen(false);
    }
  };

  const value = useMemo(
    () => ({ showNonLoggedInModal, handleClose }),
    [showNonLoggedInModal, handleClose]
  );

  return (
    <LoginModalContext.Provider value={value}>
      {children}
      <Dialog
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="login-modal"
        open={modalOpen}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 2, paddingRight: '46px !important' }}
          id="login-modal"
        >
          {loginModalMessage}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black'
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Signin />
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={() => {
          setEmailModalOpen(false);
          trackUse({
            item: 'email-sign-up-modal-closed',
            value: 'closed',
            type: 'ACTION'
          });

          logPostHogEvent('email-sign-up-closed', {
            type: 'ACTION'
          });
        }}
        aria-labelledby="email-sign-up-modal"
        open={emailModalOpen}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="email-sign-up-modal">
          Get all the hottest Amazon deals delivered straight to your email!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setEmailModalOpen(false);
            trackUse({
              item: 'email-sign-up-modal-closed',
              value: 'closed',
              type: 'ACTION'
            });

            logPostHogEvent('email-sign-up-closed', {
              type: 'ACTION'
            });
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black'
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Email signUpLocation="emailModalSignUp" showPageContainer={false} />
        </DialogContent>
      </Dialog>
    </LoginModalContext.Provider>
  );
};
