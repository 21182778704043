import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import CelebrationIcon from '@mui/icons-material/Celebration';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Drawer from '@mui/material/Drawer';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import { useTheme } from '@mui/system';

import FeedbackButton from '@components/FeedbackButton';
import colors from '@utils/colors';
import classes from '../../styles';
import SocialIcons from '../SocialIcons';
import { isCurrentlyOnPage, drawerWidth } from '../../utils';
import { usePageOnClick, PageType } from '../../pageUtils';
import { PagesType } from '../../useAppState';

const SideMenu = ({
  isDrawerOpen,
  pages,
  handleOpenNavDrawer,
  handleCloseNavDrawer,
  nestedListsOpen,
  setNestedListsOpen
}: {
  isDrawerOpen: boolean;
  pages: PagesType;
  handleOpenNavDrawer: () => void;
  handleCloseNavDrawer: () => void;
  nestedListsOpen: string[];
  setNestedListsOpen: (newState: string[]) => void;
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const pageOnClickHandle = usePageOnClick();
  const location = useLocation();
  const pathname = location?.pathname;

  const lightIconStyles = {
    color: '#fff'
  };

  const muiSelectedStyles = {
    '&.Mui-selected': {
      backgroundColor: `${colors.jdbPurple} !important`,
      color: 'white!important',
      '&:hover': {
        backgroundColor: `${colors.jdbPurple} !important`,
        color: 'white!important'
      }
    },
    '&.Mui-selected:focus-visible': {
      backgroundColor: `${colors.jdbPurple} !important`,
      color: 'white!important'
    }
  };

  const borderColor = {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, .12)'
  };

  const borderTopStyle = {
    ...borderColor,
    borderTopWidth: 'thin'
  };

  const borderBottomStyle = {
    ...borderColor,
    borderBottomWidth: 'thin'
  };

  const toggleNestedList = (name: string) => {
    if (nestedListsOpen.includes(name)) {
      setNestedListsOpen(nestedListsOpen.filter((n) => n !== name));
    } else {
      setNestedListsOpen([...nestedListsOpen, name]);
    }
  };

  const pageOnClick = (page: PageType): void => {
    if (page.children) {
      toggleNestedList(page.name);
    } else {
      pageOnClickHandle(page);
      handleCloseNavDrawer();
    }
  };

  const renderSideMenu = useCallback(() => {
    return (
      <>
        <List
          sx={{
            backgroundColor: isDarkMode ? 'transparent' : 'white',
            paddingTop: '0px !important',
            paddingBottom: '0px !important'
          }}
        >
          {pages.map((page) => {
            if (page.isHidden) {
              return null;
            }
            const extraStyles = {
              ...borderColor,
              ...(page.showDividerBefore ? borderTopStyle : {}),
              ...(page.showDividerAfter ? borderBottomStyle : {})
            };

            const isSelected = isCurrentlyOnPage(page.link, pathname);
            const primeIconClass = isSelected
              ? classes.primeIcon
              : classes.primeIconUnselected;
            const specialIconClass = page.isPrimeIcon
              ? primeIconClass
              : classes.specialIcon;
            const specialIconAnimatedClass = page.isPrimeIcon
              ? classes.primeIconAnimated
              : classes.specialIconAnimated;

            const RingingIcon = page.isPrimeIcon
              ? CelebrationIcon
              : NotificationsActiveIcon;

            const iconClassStyles = page.ringingIcon
              ? isCurrentlyOnPage(page.link, pathname)
                ? specialIconClass
                : specialIconAnimatedClass
              : '';

            const pageSelectedBaseStyles = {
              backgroundColor: colors.jdbPurple,
              color: 'white',
              '&:hover': {
                backgroundColor: colors.jdbPurple,
                color: 'white'
              }
            };

            const pageSelectedStyles = {
              ...pageSelectedBaseStyles,
              ...extraStyles
            };

            const childSelectedStyles = {
              ...pageSelectedBaseStyles,
              ...borderColor
            };

            const listItemStyles = {
              ...(isCurrentlyOnPage(page.link, pathname)
                ? pageSelectedStyles
                : extraStyles),
              ...iconClassStyles,
              ...muiSelectedStyles
            };

            const isOnThisPage = isCurrentlyOnPage(page.link, pathname);

            return (
              <React.Fragment key={page.name}>
                <ListItem
                  button
                  component="li"
                  onClick={() => {
                    pageOnClick(page);
                  }}
                  sx={listItemStyles}
                  selected={isOnThisPage}
                >
                  {page.icon && (
                    <ListItemIcon
                      sx={{
                        ...(page.ringingIcon
                          ? isOnThisPage
                            ? specialIconClass
                            : specialIconAnimatedClass
                          : {}),
                        ...(isOnThisPage && !page.ringingIcon
                          ? lightIconStyles
                          : {})
                      }}
                    >
                      {page.ringingIcon ? (
                        <RingingIcon sx={specialIconClass} />
                      ) : (
                        page.icon
                      )}
                    </ListItemIcon>
                  )}
                  <ListItemText primary={page.name} />
                  {page.children && nestedListsOpen.includes(page.name) ? (
                    <ExpandLess />
                  ) : null}
                  {page.children && !nestedListsOpen.includes(page.name) ? (
                    <ExpandMore />
                  ) : null}
                </ListItem>
                {page.children ? (
                  <Collapse
                    in={nestedListsOpen.includes(page.name)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {page.children.map((child) => {
                        const isOnChildPage = isCurrentlyOnPage(
                          child.link,
                          pathname
                        );
                        return (
                          <ListItemButton
                            sx={
                              isOnChildPage ? childSelectedStyles : borderColor
                            }
                            key={child.name}
                            onClick={() => {
                              pageOnClick(child);
                            }}
                          >
                            {child.icon ? (
                              <ListItemIcon
                                sx={isOnChildPage ? lightIconStyles : {}}
                              >
                                {child.icon}
                              </ListItemIcon>
                            ) : null}
                            <ListItemText primary={child.name} />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                ) : null}
              </React.Fragment>
            );
          })}
          <FeedbackButton />
        </List>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            ...classes.socialIcons,
            backgroundColor: isDarkMode ? 'transparent' : 'white'
          }}
        >
          <SocialIcons />
        </Box>
      </>
    );
  }, [
    classes,
    pageOnClick,
    pages,
    pathname,
    isCurrentlyOnPage,
    FeedbackButton,
    nestedListsOpen
  ]);

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
      <IconButton
        size="large"
        aria-haspopup="true"
        aria-label="Show navigation menu"
        aria-controls={isDrawerOpen ? 'menu-appbar' : undefined}
        aria-expanded={isDrawerOpen ? 'true' : undefined}
        onClick={handleOpenNavDrawer}
        color="inherit"
        sx={{
          display: {
            xl: 'none',
            lg: 'none'
          },
          zIndex: 1000
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        variant="temporary"
        onClose={handleCloseNavDrawer}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth
          }
        }}
      >
        {renderSideMenu()}
      </Drawer>
    </Box>
  );
};

export default SideMenu;
